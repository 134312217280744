img.eliteLogo {
    width: 40px;
}

.navbar {
    position: relative;
    background-color: black !important;
    padding-left: 5vw;
    padding-right: 40px;
}

#navlist {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    background: black;
}

li a {
    color: #fff;
}

#navlist li {
    /* flex: auto; */
    text-align: center;
    padding: 1.6em;
    font-size: 18px;
    color: white;
}

#navlist li:hover {
    background: #424242;
}

@media all and (max-width: 500px) {

    .navbar {
        padding: 1.6em;
    }

    #navlist {
        flex-direction: column;
        display: none;
    }

}