.styled-table {
    border-collapse: collapse;
    margin: 12px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

label.title {
    padding-right: 5px;
    padding-left: 5px;
}

form.mt-3 {
    margin-left: -10px;
}

/* label.title {
    margin-left: -12px;
    padding-right: 5px;
} */
/* .dropdown {
    margin-bottom: -21px;
    margin-top: 15px;
} */
.styled-table thead tr {
    background-color: #203e8f;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #203e8f;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #203e8f;
}
a.text-bg-primary {
    padding: 10px;
    border: transparent;
}
button.text-bg-danger {
    padding: 10px;
    border: transparent;
}