*{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  h3.alertInfo-text {
    font-size: 14px;
    margin: auto;
}
.alert.success-alert{
    font-size: 12px;
    /* width:50%;
    margin:20px auto;
    padding:30px; */
    position:relative;
    border-radius: 8px;
    box-shadow:0 0 15px 5px #ccc;
    background-color: #fff;
    border-left: 4px solid #203e8f;
  }
  .close{
    position:absolute;
    width:30px;
    height:30px;
    /* opacity:0.5; */
    border-width:1px;
    border-style:solid;
    border-radius:50%;
    right:15px;
    top:10px;
    text-align:center;
    font-size:1.6em;
    cursor:pointer;
    color: #203e8f;
  }
  .alert.danger-alert {
    font-size: 12px;
    position:relative;
    border-radius: 8px;
    box-shadow:0 0 15px 5px #ccc;
    background-color: #fff;
    border-left: 4px solid #B51019;
  }
  a.close.danger{
    position:absolute;
    width:30px;
    height:30px;
    border-width:1px;
    border-style:solid;
    border-radius:50%;
    right: 15px;
    top: 10px;
    text-align:center;
    font-size:1.5em;
    cursor:pointer;
    color: #B51019;
  }
  a.close.danger:hover {
    color: #842029;
  } 